export const PURPLE_LIGHT = '#3F2A55';
export const PURPLE_LIGHTER = '#A69EAF';
export const PURPLE_NEON = '#BC7FF0';
export const PURPLE = '#292033';
export const PURPLE_DARK = '#160A22';
export const PURPLE_DARK_HEADER = '#1B1620';
export const PINK = '#EF60A3';
export const PURPLE_TEXT = '#3F2A55';
export const GRAY = '#D8D8D8';
export const GREY_EXPERIENCE_TITLE = '#4A4A4A';
export const GRAY_TEXT = '#726C79';
export const GRAY_NAVIGATION = '#8c8c8c';
export const BLACK = '#000000';
export const WHITE = '#FFFFFF';
export const LABEL_COLOR = '#1b1620';
export const LIST_BACKGROUND = '#e1dee4';
export const SEARCH_HIGHLIGHT = '#DEFAF3';
export const PURPLE_GREY = '#5D516A';
export const GRADIENT_GREEN = 'to bottom, rgba(155, 105, 199, 0) 0%, rgba(0, 199, 197, 1) 100%';
export const GRADIENT_PINK = 'to bottom, rgba(239,96,163,0) 0%,rgba(239,96,163,0) 1%,rgba(239,96,163,1) 100%';
export const GRADIENT_YELLOW = 'to bottom, rgba(155,105,199,0) 0%,rgba(156,106,197,0) 1%,rgba(250,170,36,1) 100%';
export const GRADIENT_PURPLE_HORIZONTAL = 'to right, rgba(62,42,85,1) 0%,rgba(63,42,85,0) 100%';
export const YELLOW = '#E8E68D';
export const YELLOW_CBX = '#F9AA23';
export const SUCCESS_BACKGROUND = '#F4F4F4';
export const PINK_BACKGROUND = 'linear-gradient(90deg, rgba(239,96,163,0.75) 0%, rgba(239,96,163,0.75) 100%)';
export const AQUA_BACKGROUND = 'linear-gradient(90deg, rgba(0,199,197,0.75) 0%, rgba(0,199,197,0.75) 100%)';
export const YELLOW_BACKGROUND = 'linear-gradient(90deg, rgba(250,170,36,0.75) 0%, rgba(250,170,36,0.75) 100%)';
export const PURPLE_BACKGROUND = 'linear-gradient(90deg, rgba(63,42,85,0.75) 0%, rgba(63,42,85,0.75) 100%)';
export const GREEN_BACKGROUND = 'linear-gradient(90deg, rgba(155,210,69,0.75) 0%, rgba(155,210,69,0.75) 100%)';
