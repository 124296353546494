import React, { PropsWithChildren, useEffect, useState } from 'react';

import useShoppingCartContext from '../../../context/ShoppingCart/use-shopping-cart-context';
import { ServiceType } from '../../../domain/common';
import { getContactInformation, MEXICO_COUNTRY_ID, USA_COUNTRY_ID } from '../../../domain/checkout';
import { Ticket } from '../../../domain/tickets';
import {
  Insurance,
  InsuranceProvider,
  InsuranceQuoteRequest,
  InsuranceState,
  isInsuranceFeatureEnabled,
  isPanamericanFeatureEnabled,
  isSingleTicketInsurance,
  PassengerData,
  SingleTicketInsurance,
  TicketInsurance,
} from '../domain';
import { getTicketQuotes } from '../service/get-ticket-quotes';
import { useStatus } from '../../../hooks';
import { calculateEndDate, formatDateToString } from '../utils/insurance-utils';
import { InsuranceContext } from './insurance-context';

// ********************************************************************************
// Provides a list of Ticket Insurances that can be selected by the User, along
// with the logic to update the Cart based on the User's selection. Insurance
// should always be seen as 1 item in the Cart, containing multiple Ticket Insurances
// within it; depending on the User's selection.
// NOTE: Various ts-ignore appear throughout the file, as the Cart items are not
//       typed.
export const InsuranceContextProvider = ({ children }: PropsWithChildren) => {
  // ==============================================================================
  const { cart, addServiceToCart, updateServiceFromCart, removeServiceFromCart } = useShoppingCartContext();
  // @ts-ignore
  const tickets = cart.items.filter((product) => product.item.type === ServiceType.Ticket).map((product) => product.item) as Ticket[];

  // == State =====================================================================
  const [insurances, setInsurances] = useState<TicketInsurance[]>([]);
  const { isLoading, isError, isDone, setStatusLoading, setStatusError, setStatusDone } = useStatus();

  // == Effects ===================================================================

  useEffect(() => {
    // Tickets is only recomputed when Tickets in the Cart change, not when
    // the cart itself changes.
    getTicketInsurances();
  }, [tickets.length]);

  // == Handlers ==================================================================
  // Load the Insurances based on the Tickets in the Cart.
  const getTicketInsurances = async () => {
    if (!isInsuranceFeatureEnabled()) return setInsurances([]);

    const contactInformation = getContactInformation();

    // To get a Quote from a Ticket, it's necessary to know the Passenger's
    // information, which is stored in the Contact Information.
    if (!tickets || tickets.length === 0 || !contactInformation) {
      setInsurances([]);
      return;
    }

    const insuranceQuoteRequests: InsuranceQuoteRequest[] = tickets.map((ticket) => {
      let passengersData: PassengerData[] = [];
      // If it's an Assisted Ticket, the Passenger's data is stored differently.
      if (ticket.assisted_data !== undefined) {
        const assistedPassPassenger = ticket.assisted_data.passengerInformation;
        passengersData = [
          {
            firstName: assistedPassPassenger.firstName,
            lastName: assistedPassPassenger.lastName,
            country: getCountryName(Number(assistedPassPassenger.countryOfResidence)),
            state: assistedPassPassenger.stateOfResidence as string,
            tripCost: ticket.price,
          },
        ];
      } else {
        const travelers = contactInformation.travelers.find((traveler) => traveler.ticketId === ticket.id)?.values || [];
        passengersData = travelers.map((traveler) => ({
          firstName: traveler.firstName,
          lastName: traveler.lastName,
          country: getCountryName(Number(traveler.countryOfResidence)),
          state: traveler.stateOfResidence,
          tripCost: ticket.price,
        }));
      }

      // NOTE: For US now, only California(CA) residents are elegible for the Insurance.
      //       This should be a configuration in the future. This only applies to
      //       the US, as in Mexico all residents are elegible.
      passengersData = passengersData.filter((passenger) => {
        if (passenger.country === 'USA') {
          return passenger.state === 'CA';
        }
        return true;
      });

      // Filter out the passengers that are traveling TO their residence country, e.g. a Mexican
      // traveling to Mexico. This is because the insurance is not valid for those cases.
      passengersData = passengersData.filter((passenger) => passenger.country !== ticket.destination);

      // Departure Date can be a Date object or a string (depending on the source). If it's a Date object,
      // convert it to a string.
      const startDate = formatDateToString(ticket.departureDate);
      let endDate = ticket.returnDate ? formatDateToString(ticket.returnDate) : undefined;

      // When the Ticket is a One-Way Ticket, by default + 7 days to the start date.
      if (!endDate) {
        let coverageDays = 7; /* default */

        // Find if there's already an entry in the Insurances Array for this Ticket and quote
        const existingInsurance = insurances.find((insurance) => insurance.ticketId === ticket.id);
        if (existingInsurance && isSingleTicketInsurance(existingInsurance)) coverageDays = existingInsurance.coverageDays;

        endDate = calculateEndDate(startDate, coverageDays);
      }

      return {
        ticketId: ticket.id,
        travelType: ticket.tripType,
        destination: ticket.destination,
        startDate,
        endDate,
        passengersData,
      };
    });

    // Make the API call to get the Insurances Quotes based on the Tickets in the Cart.
    // For now just returning a default list of Insurances.
    try {
      setStatusLoading();
      const ticketInsurances = await getTicketQuotes(insuranceQuoteRequests);
      const insurances: TicketInsurance[] = ticketInsurances.map((ticketInsurance, index) => {
        // NOTE: Assuming that the tickets are in the same order as the insurances.
        const ticketInfo = tickets[index];
        const quotes = ticketInsurance.insurances;

        return {
          ticketId: ticketInfo.id,
          travelType: ticketInfo.tripType,
          destination: ticketInfo.destination,
          startDate: ticketInsurance.startDate,
          endDate: ticketInsurance.endDate,
          state: InsuranceState.NOT_SELECTED /* default state */,
          coverageDays: 7 /* default coverage days */,
          total: ticketInsurance.total,
          quotes,
        } as TicketInsurance;
      });

      // If previous Insurances were selected, or are already in the Cart, restore the state.
      setInsurances((previousInsurances) => {
        return insurances.map((newInsurance) => {
          const prevInsurance = previousInsurances.find((insurance) => insurance.ticketId === newInsurance.ticketId);

          // @ts-ignore
          const insuranceServiceInCart = cart.items.find((service) => service.type === ServiceType.Insurance && service.item.ticketId === newInsurance.ticketId);
          if (insuranceServiceInCart) {
            newInsurance.state = InsuranceState.ACCEPTED;
            if (isSingleTicketInsurance(newInsurance)) {
              // @ts-ignore
              newInsurance.coverageDays = (insuranceServiceInCart.item as SingleTicketInsurance).coverageDays;
              newInsurance.endDate = calculateEndDate(newInsurance.startDate, newInsurance.coverageDays);
            }
          } else {
            if (prevInsurance && prevInsurance.state === InsuranceState.DECLINED) {
              newInsurance.state = InsuranceState.DECLINED;
            }
            if (isSingleTicketInsurance(newInsurance)) {
              newInsurance.coverageDays = prevInsurance ? (prevInsurance as SingleTicketInsurance).coverageDays : 7 /*default*/;
              newInsurance.endDate = calculateEndDate(newInsurance.startDate, newInsurance.coverageDays);
            }
          }

          return newInsurance;
        })
        // Filter out the insurances from Panamerican if the feature is disabled.
        .filter((insurance) => {
          if(isPanamericanFeatureEnabled()) return true;
          return insurance.quotes.some((quote) => quote.provider !== InsuranceProvider.PANAMERICAN);
        });
      });
      setStatusDone();
    } catch (e) {
      console.log(e);
      setStatusError();
    }
  };

  // When the user makes a selection on the UI, this method will be called to
  // update the Insurance List and the Cart.
  const onTicketInsuranceChange = (ticketInsurance: TicketInsurance) => {
    const updatedInsurances = insurances.map((insurance) => (insurance.ticketId === ticketInsurance.ticketId ? ticketInsurance : insurance));
    setInsurances(updatedInsurances);

    // Find if the cart has an Insurance Service for this Ticket already to update it.
    // @ts-ignore
    // NOTE: Ignoring because there is no type for the items in the Cart
    const insuranceService = cart.items.find((service) => service.type === ServiceType.Insurance && service.item.ticketId === ticketInsurance.ticketId);

    // If the Insurance was accepted, add it to the Cart or edit the existing one.
    if (ticketInsurance.state === InsuranceState.ACCEPTED) {
      if (!insuranceService) addServiceToCart(new Insurance(ticketInsurance), ServiceType.Insurance);
      else {
        // @ts-ignore
        updateServiceFromCart({ ...insuranceService.item, ...ticketInsurance });
      }
    } else {
      // If the Insurance was declined or not selected, remove it from the Cart.
      if (insuranceService) {
        // @ts-ignore
        removeServiceFromCart(insuranceService.item);
      } /* nothing to do */
    }
  };

  // To reflect the changes in the quote when the User changes the coverage days,
  // this functions makes an API call to get the updated quote, then updates the
  // insurance list and if necessary, the Cart.
  const onCoverageDaysChange = async (ticketInsurance: SingleTicketInsurance) => {
    // Search for the travelers in the Contact Information to get the Passenger's data.
    const contactInformation = getContactInformation();
    if (!contactInformation) return;

    // Find the ticket in the Cart to get the Passenger's data in case of an
    // Assisted Ticket.
    const ticket = tickets.find((ticket) => ticket.id === ticketInsurance.ticketId);
    let passengersData: PassengerData[] = [];
    // If it's an Assisted Ticket, the Passenger's data is stored differently.
    if (ticket && ticket.assisted_data !== undefined) {
      const assistedPassPassenger = ticket.assisted_data.passengerInformation;
      passengersData = [
        {
          firstName: assistedPassPassenger.firstName,
          lastName: assistedPassPassenger.lastName,
          country: getCountryName(Number(assistedPassPassenger.countryOfResidence)),
          state: assistedPassPassenger.stateOfResidence as string,
          tripCost: ticket.price,
        },
      ];
    } else {
      const travelers = contactInformation.travelers.find((traveler) => traveler.ticketId === ticketInsurance.ticketId)?.values || [];
      passengersData = travelers.map((traveler) => ({
        firstName: traveler.firstName,
        lastName: traveler.lastName,
        country: getCountryName(Number(traveler.countryOfResidence)),
        state: traveler.stateOfResidence,
        tripCost: ticketInsurance.total,
      }));
    }

    // Make the API call to get the Insurances Quotes based on the Tickets in the Cart.
    const insuranceQuoteRequest: InsuranceQuoteRequest = {
      startDate: ticketInsurance.startDate,
      endDate: calculateEndDate(ticketInsurance.startDate, ticketInsurance.coverageDays),
      ticketId: ticketInsurance.ticketId,
      destination: ticketInsurance.destination,
      passengersData,
    };

    // NOTE: Not putting the global loading state here, as it's a single request and the
    //       loading state is handled by the component that calls this function.
    const ticketInsurances = await getTicketQuotes([insuranceQuoteRequest] /*single request*/);

    const updatedInsurance: TicketInsurance = {
      ticketId: ticketInsurance.ticketId,
      travelType: ticketInsurance.travelType,
      destination: ticketInsurance.destination,
      startDate: ticketInsurance.startDate,
      endDate: ticketInsurance.endDate,
      coverageDays: ticketInsurance.coverageDays,
      state: ticketInsurance.state,
      total: ticketInsurances[0].total,
      quotes: ticketInsurances[0].insurances,
    };

    onTicketInsuranceChange(updatedInsurance);
  };

  // == Utils =====================================================================
  const getCountryName = (countryId: number) => {
    switch (countryId) {
      case MEXICO_COUNTRY_ID:
        return 'MX';
      case USA_COUNTRY_ID:
        return 'USA';
      default:
        return 'Other';
    }
  };

  // == Render ====================================================================
  return (
    <InsuranceContext.Provider value={{ insurances, getTicketInsurances, onTicketInsuranceChange, onCoverageDaysChange, isLoading, isError, isDone }}>
      {children}
    </InsuranceContext.Provider>
  );
};
