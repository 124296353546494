export const getFieldError = ({touchedFields, errors}, name) => {
    if(!(/[.\[\]]/).test(name)) return touchedFields[name] && errors[name]

    let isTouched = touchedFields;
    let error = errors;
    name.match(/[^[\].]+/g).forEach((s) => {
      isTouched = isTouched?.[s]
      error = error?.[s]
    });

    return isTouched && error
}