import React from 'react';
import { Link } from 'gatsby';

import CheckIcon from '@mui/icons-material/Check';
import { Icon, Typography, Stack, Grid, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useIsMobile } from '../../../../hooks';
// @ts-ignore
import infoIcon from '../../../../legacy/assets/images/icons/info.svg';
import { InsuranceProvider, isPanamericanFeatureEnabled } from '../../domain';

// ********************************************************************************
type BenefitObjectType = {
  text: string;
  tooltip: string;
};

const Benefits = ({ provider }: { provider: InsuranceProvider }) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const properties = t(isPanamericanFeatureEnabled() ? `insurance_service.providers.${provider}.card.benefits` : `insurance_service.card.benefits`, {
    returnObjects: true,
  }) as BenefitObjectType[];

  // Split benefits in columns of the same size
  const COLUMN_SIZE = 3;
  // e.g. CS = 3, [1, 2, 3, 4] = [[1, 2, 3], [4]]
  const splitedBenefits = properties.reduce((acc, item, index) => {
    const currentColumn = Math.floor(index / COLUMN_SIZE);
    if (!acc[currentColumn]) {
      acc[currentColumn] = [];
    }
    acc[currentColumn].push(item);
    return acc;
  }, [] as BenefitObjectType[][]);

  return (
    <>
      <Grid container width="100%" direction={isMobile ? 'column' : 'row'} gap={isMobile ? '.3em' : 0}>
        {splitedBenefits.map((benefits, index) => (
          <Grid item xs={6} paddingLeft="calc(1rem + 50px)" display="flex" flexDirection="column" gap=".4rem">
            {benefits.map((item, index) => (
              <Stack display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start" gap=".6rem" key={'benefits-2-' + index} paddingRight="1rem">
                <Icon
                  component={CheckIcon}
                  sx={{
                    color: '#EF60A3',
                    fontSize: '1rem',
                  }}
                />

                <Typography
                  variant="caption"
                  width={isMobile ? '38vw' : 'max-content'}
                  maxWidth={isMobile ? '38vw' : 'max-content'}
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '80vh',
                  }}
                >
                  {item.text}
                </Typography>
                {/* <Tooltip title={item.tooltip} placement="top">
                    <img src={infoIcon} alt={item.tooltip} />
                  </Tooltip> */}
              </Stack>
            ))}
          </Grid>
        ))}

        {/* <Grid item xs={12} marginTop="1rem" marginLeft="calc(1rem + 100px)">
          <Stack display="flex" flexDirection="row" alignItems="center" gap=".4rem">
            <Link to="https://documents.battleface.com/terms_and_conditions/cbx-important-notices-and-disclosures.pdf">
              <Typography variant="caption" fontFamily="Gotham-Bold" sx={{ textDecoration: 'underline', cursor: 'pointer' }}>
                {t(`insurance_service.card.terms_and_conditions.text`)}
              </Typography>
            </Link>
            <Tooltip title={t(`insurance_service.card.terms_and_conditions.tooltip`)} placement="top">
              <img src={infoIcon} alt={t(`insurance_service.card.terms_and_conditions.tooltip`)} />
            </Tooltip>
          </Stack>
        </Grid> */}
      </Grid>
    </>
  );
};
export default Benefits;
