import { useState, useEffect } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import getInamiService from '../services/getInami.service';

// ********************************************************************************
// As this is a static data, it can be cached to avoid fetching it multiple times
const cache = {
  en: [],
  es: [],
};

export default function useInamiCountries() {
  const {
    i18n: { language },
  } = useTranslation();
  const [countries, setCountries] = useState([]);

  const fetchCountries = async () => {
    try {
      let inamiCountries = [];
      if (cache[language].length) {
        inamiCountries = cache[language];
      } else {
        inamiCountries = await getInamiService({ locale: language });
        cache[language] = inamiCountries;
      }

      let labelField = `${language}Desc`;
      setCountries(
        inamiCountries.map((country) => ({
          label: country[labelField] ?? country.name,
          value: country.id,
        })),
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { inamiCountries: countries };
}
