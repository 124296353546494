import React from 'react';
import { onValue } from "firebase/database";
import { exists, getKey } from '../../../helpers';
import { withAuthentication } from '..';


/**
 * with authentication hoc
 * @param {function} Component - component hoc
 * @returns {function}
 */
const withUser = (Component) => {
  /**
   * With Authentication class
   */
  class WithUser extends React.PureComponent {
    /**
     * constructor class
     * @param {*} props - class props
     */
    constructor(props) {
      super(props);

      this.state = {
        user: null
      };
    }

    /**
   * Gets user info
   * @param {Object} userInfo - user info from firebase
   */
    getUserInfo() {
      const { userInfo, firebase } = this.props;
      const uid = getKey(userInfo, 'uid');

      if (uid) {
        onValue(firebase.user(userInfo.uid), (snapshot) => {
          this.setState({
            user: {...snapshot.val(), uid},
          });
        });
      }
    }

    /**
     * Render method
     * @returns {JSX}
     */
    render() {
      const {
        props: {
          userInfo,
        },
        state: {
          user
        }
      } = this;

      if (exists(userInfo) && !exists(user)) this.getUserInfo();

      return (
        <Component {...this.props} user={user} uid={getKey(userInfo, 'uid')} />
      );
    }
  }

  return withAuthentication(WithUser);
};

export default withUser;
