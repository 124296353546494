import React, { Fragment } from 'react';
import styled from 'styled-components';
import Styles from './SelectRadioButton.Styles';

const ButtonContainer = styled.div`${Styles.buttonContainer}`;
const CheckedCircle = styled.div`${Styles.checkedCircle}`;
const UncheckedCircle = styled.div`${Styles.uncheckedCircle}`;

const SelectRadioButton = ({ isSelected, id, selectedColor }) => {
    return (
            <ButtonContainer >
                {isSelected === id  ? (
                    <CheckedCircle style={{ backgroundColor: selectedColor }} />
                ) : (
                    <UncheckedCircle />
                )}
            </ButtonContainer>
    );
};

export default SelectRadioButton;