import { Yup } from '../../utils/yup-locale';

// ********************************************************************************
// This form is used exclusively in the companions process to collect and validate
// the contact information of the user's companions.
// NOTE: This form only purpose is to render and validate.

export type ICompanion = {
  id?: string;
  firstName: string;
  lastName: string;
  email: string;
  gender: string;
  nationality: string;
  countryOfBirth: number;
  countryOfResidence: number;
  stateOfResidence?: string;
  savePassenger?: boolean;
};

export const DEFAULT_COMPANIONS_FORM: ICompanion = {
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  gender: '',
  nationality: '',
  countryOfBirth: 0,
  countryOfResidence: 0,
  stateOfResidence: '',
  savePassenger: false,
};

// -- Schema --------------------------------------------------------------------
export const companionsFormSchema = Yup.object().shape({
  firstName: Yup.string().trim().required(),
  lastName: Yup.string().trim().required(),
  email: Yup.string().email().optional(),
  gender: Yup.string().optional(),
  nationality: Yup.string().optional(),
  countryOfBirth: Yup.number().optional(),
  countryOfResidence: Yup.number().optional(),
  stateOfResidence: Yup.string().optional(),
});

export const COMPANIONS_KEY = 'companions_v3';
