import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormSelect, FormSelectProps } from '../../../../../components/form';
import { getFieldError } from '../../../../../utils';

// ********************************************************************************
type Props = {
  name: string;
  rules?: any;
  gap?: boolean;
  onChange?: (event: any) => void;
} & Omit<FormSelectProps, 'name' | 'value' | 'onChange'>;
export const FormSelectController = ({ name, rules, gap, ...props }: Props) => {
  const { t } = useTranslation();
  const { control, formState } = useFormContext();
  // == State ===============================================================
  const error = getFieldError(formState, name);

  const handleChange = (onChange: (...event: any[]) => void) => (event) => {
    onChange(event);
    props.onChange?.(event)
  };

  // == Render ==============================================================
  return (
    <Controller
      rules={rules}
      control={control}
      name={name}
      render={({ field }) =>{
      let value = field.value ?? '';
      return (
        <FormSelect
          name={name}
          value={value}
          onBlur={field.onBlur}
          error={!!error}
          helperText={error ? t(`${error.message}` /*cast*/) : ''}
          {...props}
          onChange={handleChange(field.onChange)}
          gap={gap}
        />
      )}}
    />
  );
};
