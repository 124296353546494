import { useState } from 'react';

export const useStatus = () => {
  const [status, setStatus] = useState<'idle' | 'loading' | 'done' | 'error'>('idle');

  const isIdle = status === 'idle';
  const isLoading = status === 'loading';
  const isDone = status === 'done';
  const isError = status === 'error';

  const setStatusIdle = () => setStatus('idle');
  const setStatusLoading = () => setStatus('loading');
  const setStatusDone = () => setStatus('done');
  const setStatusError = () => setStatus('error');

  return { isIdle, isLoading, isDone, isError, setStatusIdle, setStatusLoading, setStatusDone, setStatusError };
};
