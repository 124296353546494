import React from 'react';
import { Box, Typography } from '@mui/material';
import { GRAY, PINK, GREY_EXPERIENCE_TITLE } from '../../../legacy/constants/colors';

const BadgeName = ({ disabled = true, isSelected = false, setActiveCompanion, initials = false, ...companion }) => {
  const passengerInitials = companion.firstName.charAt(0).toUpperCase() + companion.lastName.charAt(0).toUpperCase();

  const passengerFirstName = companion.firstName.split(' ').splice(0, 1).join(' ').toUpperCase();
  const passengerLastName = companion.lastName.split(' ').splice(0, 1).join(' ').toUpperCase();
  const passengerFullName = `${passengerFirstName} ${passengerLastName}`;

  return (
    <Box
      onClick={() => {
        if (!disabled) {
          setActiveCompanion(companion);
        }
      }}
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      width={initials ? 40 : 150}
      height={initials ? 40 : 20}
      bgcolor={GRAY}
      padding={initials ? 0 : 1.5}
      borderRadius="20px"
      sx={{
        border: `2px solid transparent`,
        ...(!disabled && {
          cursor: 'pointer',
          '&:hover': {
            border: `2px solid ${PINK}`,
          },
        }),
        ...(isSelected && {
          border: `2px solid ${PINK}`,
        }),
      }}
    >
      <Typography
        textAlign="center"
        justifyContent="center"
        color={GREY_EXPERIENCE_TITLE}
        fontSize={initials ? 12 : 9}
        fontWeight={900}
        sx={{
          display: 'inline-block',
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {initials ? passengerInitials : passengerFullName}
      </Typography>
    </Box>
  );
};

export default BadgeName;
