import axios from 'axios';

import { baseUrl } from '../../../api/api';
import { getTicketQuotesRequest, getTicketQuotesResponse, InsuranceQuoteResponse } from '../domain';

// ********************************************************************************
// Gets ticket quotes from the Insurance API, based on the Tickets provided.
export const getTicketQuotes = async (tickets: getTicketQuotesRequest): Promise<InsuranceQuoteResponse[]> => {
  const response = await axios.post<getTicketQuotesResponse>(`${baseUrl}/insurance/pricing`, tickets);
  return response.data.data;
};
