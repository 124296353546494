import React, { Fragment } from 'react';
import { Container } from '@mui/material';
import BadgeName from './badge-name';
import { useWatch } from 'react-hook-form';

const BadgeNameList = ({ control, firstNameKey, lastNameKey, companions, setActiveCompanion }) => {

  if (!companions) return;
  /**
   * Subscribe to following passenger (specific) form fields:
   * - First name.
   * - Last name.
   */
  const firstName = useWatch({ control, name: firstNameKey || 'firstName' });
  const lastName = useWatch({ control, name: lastNameKey || 'lastName' });

  const getPassengerName = (passenger) => {
    return passenger.firstName?.toLowerCase() + passenger.lastName?.toLowerCase();
  };

  const isPassengerSelected = (passenger) => getPassengerName(passenger) === getPassengerName({ firstName, lastName });

  return (
    <Fragment>
      <Container
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          margin: '0px',
          padding: '0px',
          gap: '16px',
        }}
      >
        {companions?.map((companion, index) => (
          <BadgeName
            {...companion}
            key={companion?.id}
            disabled={false}
            setActiveCompanion={setActiveCompanion}
            isSelected={isPassengerSelected(companion)}
          />
        ))}
      </Container>
    </Fragment>
  );
};

export default BadgeNameList;
