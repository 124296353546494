import { css } from 'styled-components';

export default {
     buttonContainer: css`
          display: flex;
          height: 20px;
          width: 20px;
          flexDirection: row;
          justifyContent: flex-start;
          align-items: center;
          border-radius: 100%;
          align-content: center;
          justify-content: center;
          border-width: 1px;
          border-style: solid;
          border-width: initial;
          border-color: #ACACAC;
    `,

     checkedCircle: () => css`
          height: 10px;
          width: 10px;
          border-radius: 100px;
          border-width: 4px;
          align-items: center;
          justify-content: center;
          background-color: #3F2A55;
     `,

     uncheckedCircle: () => css`
         width: 13;
         height: 13;
         border-radius: 100;
         background-color: white;
     `,

};
