import React, { Fragment } from 'react';
import { Container } from '@mui/material';
import VehicleBadge from './VehicleBadge';

const VehiclesBadgesList = ({ vehicles, setActiveVehicle, comparableVehicleLicense }) => {
  
  return (
    <Fragment>
      <Container
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          margin: '0px',
          padding: '0px',
          gap: '16px',
        }}
      >
        {vehicles?.map((vehicle, index) => (
          <VehicleBadge vehicle={vehicle} onlyBadge={true} setActiveVehicle={setActiveVehicle} comparableVehicleLicense={comparableVehicleLicense} />
        ))}
      </Container>
    </Fragment>
  );
};

export default VehiclesBadgesList;
