import React, { useEffect, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useIsMobile } from '../../../../hooks';
import { useInsuranceService } from '../../hooks';
import { InsuranceCard } from './insurance-card';
import { Loader } from '../../../../components/Loader';

// ********************************************************************************
export const InsuranceBanner = () => {
  const { insurances, getTicketInsurances, isLoading, isError  } = useInsuranceService();

  useEffect(() => {
    getTicketInsurances();
  }, []);

  const isMobile = useIsMobile();
  const { t } = useTranslation();

  if (isLoading)
    return (
      <Box width="100px" margin="auto">
        <Loader />
      </Box>
    );

  // TODO: Add error message if needed
  if (isError) return null;

  if ((insurances.length === 0 /*no insurances*/ || insurances.every((insurance) => insurance.total === 0)) /* not elegible*/) {
    return null;
  }

  return (
    <Box id="insurance-banner" sx={{ boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.10)' }}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          height: isMobile ? '' : '194px',
          position: 'relative',
        }}
      >
        <Box width={isMobile ? '100%' : '20%'} height="194px">
          <img
            src="/images/Insurance/family_with_tickets.jpeg"
            alt="Family with tickets"
            style={{
              width: '100%',
              height: '194px',
              borderRadius: isMobile ? '8px 8px 0px 0px' : '8px 0px 0px 0px',
              objectFit: 'cover',
              objectPosition: '50% 10%',
            }}
          />
        </Box>
        <Box
          sx={{
            width: isMobile ? '100%' : '80%',
            padding: '24px 32px',
            backgroundColor: '#3F2A55',
            borderRadius: isMobile ? '' : '0px 8px 0px 0px',
          }}
        >
          <Typography variant="h5" color="white" gutterBottom>
            {t('insurance_service.card.title')}
          </Typography>
          <Typography variant="body1" color="white" maxWidth={'calc(100% - 120px)'} gutterBottom>
            {t('insurance_service.card.description')}
          </Typography>
        </Box>
        <Box
          component="figure"
          position="absolute"
          top={0}
          right={0}
          zIndex={10}
          width="100vw"
          bgcolor="#7064AE"
          borderRadius={'0px 8px 0px 0px'}
          sx={{ clipPath: 'polygon(0 0, 100% 100%, 100% 0);' }}
          maxWidth="120px"
          height="120px"
        />
        <Box
          component="figure"
          position="absolute"
          bottom={0}
          right={0}
          margin={0}
          zIndex={10}
          width="100vw"
          bgcolor="#EC3B96"
          sx={{ clipPath: 'polygon(100% 0, 0 100%, 100% 100%);' }}
          maxWidth="120px"
          height="120px"
        />
      </Box>
      <Box>
        {insurances.map((ticket, index) => (
          <Box>
            <InsuranceCard ticketNumber={index + 1} ticket={ticket} />
            {!!insurances.length && index < insurances.length - 1 && (
              <Box
                sx={{
                  borderTop: '1px solid #6C6D70',
                  opacity: '0.2',
                  width: 'auto',
                  margin: '0 2rem',
                }}
              />
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
};
