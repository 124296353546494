import { Service, ServiceType } from '../../../domain/common';
import { TravelType } from '../../../domain/travels';

// ********************************************************************************
// -- Domain -----------------------------------------------------------------------
export type TicketInsurance = RoundTicketInsurance | SingleTicketInsurance;

export type BaseTicketInsurance = {
  ticketId: string;
  travelType: TravelType;
  state: InsuranceState;
  startDate: string;
  destination: string;
  quotes: Quote[];
  endDate: string;
  total: number;
};

export type RoundTicketInsurance = BaseTicketInsurance & {
  travelType: TravelType.RoundTrip;
  
};
export const isRoundTicketInsurance = (ticket: TicketInsurance): ticket is RoundTicketInsurance => {
  return ticket.travelType === TravelType.RoundTrip;
};

export type SingleTicketInsurance = BaseTicketInsurance & {
  travelType: TravelType.OneWay;
  coverageDays: number;
};
export const isSingleTicketInsurance = (ticket: TicketInsurance): ticket is SingleTicketInsurance => {
  return ticket.travelType === TravelType.OneWay;
};

// =================================================================================
// The Insurance Service as it will be stored in the Shopping Cart.
export class Insurance extends Service {
  // =================================================================
  public readonly ticketId: string;
  public readonly travelType: TravelType;
  public readonly startDate: string;
  public readonly destination: string;
  public readonly quotes: Quote[];
  // For one-way tickets, the User can select the number of days they want
  // to be covered by the Insurance; for round-trip tickets, the end date
  // is given by the Ticket.
  public readonly coverageDays?: number;
  public readonly endDate?: string;

  // == Lifecycle =================================================================
  constructor(insurance: TicketInsurance) {
    super({
      type: ServiceType.Insurance,
      quantity: 1 /*single*/,
      total: insurance.total,
    });
    this.ticketId = insurance.ticketId;
    this.startDate = insurance.startDate;
    this.destination = insurance.destination;
    this.quotes = insurance.quotes;
    this.travelType = TravelType.RoundTrip /*default*/;
    this.endDate = insurance.endDate;

    if (isRoundTicketInsurance(insurance)) {
      this.travelType = TravelType.RoundTrip;
    }

    if (isSingleTicketInsurance(insurance)) {
      this.travelType = TravelType.OneWay;
      this.coverageDays = insurance.coverageDays;
    }
  }
}

// --------------------------------------------------------------------------------
export type Quote = {
  provider: InsuranceProvider;
  quoteId: string;
  price: number;
  passengersData: PassengerData[];
};

export type PassengerData = {
  firstName: string;
  lastName: string;
  country: string;
  state: string;
  tripCost: number;
};

export enum InsuranceProvider {
  BATTLEFACE = 'battleface',
  PANAMERICAN = 'panamerican',
}

export enum InsuranceState {
  ACCEPTED = 'accepted',
  DECLINED = 'declined',
  NOT_SELECTED = 'not_selected',
}

export const isPanamericanFeatureEnabled = () => {
  return !!process.env.GATSBY_FEATURE_PANAMERICAN_ENABLED;
}

export const isInsuranceFeatureEnabled = () => {
  return !!process.env.GATSBY_FEATURE_INSURANCE_ENABLED;
};
