import { useEffect, useState } from 'react';

export const useCurrentPosition = () => {
  const [currentPosition, setCurrentPosition] = useState<GeolocationPosition | null>(null);

  useEffect(() => {
    /**
     * Check if navigator and geolocation are available.
     */
    if (!navigator || !navigator.geolocation) return;
    /**
     * Retrieve the user's position.
     */
    navigator.geolocation.getCurrentPosition(setCurrentPosition);
  }, []);

  return { currentPosition };
};
