import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { AssistedTicketFlight, AssistedTicketPassenger, getAssistedPassAirlines } from '../../../../domain/tickets';
import { isOneWayTrip } from '../../../../domain/travels';
import { Yup } from '../../../../utils/yup-locale';
import { AssistedTicketFlightForm } from './assisted-ticket-flight-form';
import { AssistedTicketPassengerForm } from './assisted-ticket-passenger-form';
import Firebase from '../../../../legacy/components/Firebase';
import { isInsuranceFeatureEnabled } from '../../../insurance';
import { MEXICO_COUNTRY_ID, USA_COUNTRY_ID } from '../../../../domain/checkout';
//import { useCompanions } from '../../../travelers/hooks/use-companion';

const assistedTicketFlightSchema = Yup.object().shape({
  airline: Yup.string(),
  date: Yup.string(),
  destination: Yup.string(),
  flightNumber: Yup.string(),
  origin: Yup.string(),
});

// NOTE: Before the Insurance feature was enabled, the passenger data on Assited Ticket
// was optional, now it is required. To avoid conflicts with the form validation
// the schema was separated into two different schemas used based on the Insurance feature.
// FIXME: Remove the optional schema when the Feature Flag is removed.
const assistedTicketPassengerOptionalSchema = Yup.object().shape({
  age: Yup.number()
    .nullable()
    .optional()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  firstName: Yup.string().optional(),
  gender: Yup.string().optional(),
  lastName: Yup.string().optional(),
  nationality: Yup.string().optional(),
  telephone: Yup.string().optional(),
  countryOfResidence: Yup.string().optional(),
  stateOfResidence: Yup.string().optional(),
});

export const assistedTicketPassengerRequiredSchema = Yup.object().shape({
  age: Yup.number()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required(),
  firstName: Yup.string().required(),
  gender: Yup.string().required(),
  lastName: Yup.string().required(),
  nationality: Yup.string().required(),
  telephone: Yup.string().required(),
  countryOfResidence: Yup.string().required(),
  stateOfResidence: Yup.string().when('countryOfResidence', {
    is: (value: string) => value === `${USA_COUNTRY_ID}` || value === `${MEXICO_COUNTRY_ID}`,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.optional(),
  }),
});

const assistedTicketPassengerSchema = isInsuranceFeatureEnabled() ? assistedTicketPassengerRequiredSchema : assistedTicketPassengerOptionalSchema;

interface Props {
  ticket: any;
  onFlightChange: (field: keyof AssistedTicketFlight, value: string) => void;
  onPassengerChange: (field: keyof AssistedTicketPassenger, value: number | string) => void;
  user: any;
  firebase: Firebase;
}

export const AssistedPassContactInfoForm = ({ ticket, onFlightChange, onPassengerChange, user, firebase }: Props) => {
  const { t } = useTranslation();
  const travelTypei18n = isOneWayTrip(ticket.tripType) ? 'trip_types.one_way' : 'trip_types.round_trip';
  //const { companions } = useCompanions(user, firebase);

  return (
    <>
      <Stack gap={1}>
        <Typography fontWeight={700}>
          {t('ticket')} {t(travelTypei18n)}
        </Typography>
        <Stack flexDirection="row" gap={1}>
          <Typography fontWeight={700}>{t('tickets_service.passenger_types.assisted_traveler')}:</Typography>
          <Stack flexDirection="row" gap={2}>
            <Typography>{ticket.origin}</Typography>
            <Typography>-</Typography>
            <Typography>{ticket.destination}</Typography>
          </Stack>
        </Stack>
      </Stack>

      <AssistedTicketPassengerForm
        passenger={ticket.assisted_data.passengerInformation}
        validationSchema={assistedTicketPassengerSchema}
        onChange={onPassengerChange}
        //companions={companions}
      />
      <AssistedTicketFlightForm
        airlines={getAssistedPassAirlines()}
        flight={ticket.assisted_data.flightInformation}
        validationSchema={assistedTicketFlightSchema}
        onChange={onFlightChange}
      />
    </>
  );
};
