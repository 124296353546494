import { Nationality } from '../common';


export enum ContactPersonField {
  FirstName = 'firstName',
  LastName = 'lastName',
  Nationality = 'nationality',
  Email = 'email',
  EmailConfirm = 'emailConfirm',
  ZipCode = 'zipCode',
  Phone = 'phone',
  StateOfResidence = 'stateOfResidence',
  CountryOfResidence = 'countryOfResidence'
}

export interface IContactPerson {
  firstName: string;
  lastName: string;
  nationality: Nationality;
  email: string;
  emailConfirm: string;
  zipCode: string;
  phone: string;
  countryOfResidence: string;
  stateOfResidence?: string;
}
