import React from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { Checkbox, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ICompanion } from '../../../domain/companions';

type Props = {
  control: any;
  companions: ICompanion[];
  onChange: (val: boolean) => void;
  fieldNamePrefix: string;
};

const SavePassengerCheckbox = ({ control, companions, onChange, fieldNamePrefix }: Props) => {
  const { t } = useTranslation();

  const firstName = useWatch({ control, name: fieldNamePrefix ? `${fieldNamePrefix}.firstName` : 'firstName'});
  const lastName = useWatch({ control, name: fieldNamePrefix ? `${fieldNamePrefix}.lastName` : 'lastName' });

  /**
   *
   * @param {ICompanion[]} passenger
   */
  const isPassengerSelected = (passenger) =>
    passenger.firstName.toLowerCase() + passenger.lastName.toLowerCase() ===
    firstName?.toLowerCase() + lastName?.toLowerCase();

  /* === RENDER ================================================================ */
  if (companions.find(isPassengerSelected)) return null;

  return (
    <Stack direction="row" alignItems="center" gap={0.5}>
      <Controller
        control={control}
        name={fieldNamePrefix ? `${fieldNamePrefix}.savePassenger` : 'savePassenger'}
        render={({ field }) => (
          <Checkbox
            sx={{ color: '#6C6D70', padding: 0 }}
            checked={field.value ?? false}
            value={field.value}
            onChange={(e) => {
              field.onChange(e.target.checked);
   
              onChange(e.target.checked);
            }}
          />
        )}
      />
      <Typography variant="body2" color="secondary.light">
        {t('checkout_contact_information.main_traveler.inputs.save_passenger')}
      </Typography>
    </Stack>
  );
};

export default SavePassengerCheckbox;