import React, { useEffect, useMemo } from 'react';

import { Checkbox, Grid, Stack, Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';

import { shuttlePassengersSchema } from '../../../../domain/checkout';
import { ICompanion } from '../../../../domain/companions';
import { ShuttlePassenger } from '../../../../domain/shuttle';
import Firebase from '../../../../legacy/components/Firebase';
import { LocalStorage } from '../../../../utils';
import BadgeNameList from '../../../companions/components/badge-name-list';
import SavePassengerCheckbox from '../../../companions/components/save-passenger-checkbox';
import { useCompanions } from '../../../travelers/hooks/use-companion';
import { FormTextFieldController } from './components/text-field-controller';

// ********************************************************************************
type Props = {
  index: number;
  passengersQ: number;
  cartShuttleItem: ShuttlePassenger;
  handleCartChange: (key: string, val: string | boolean) => void;
  user: any;
  firebase: Firebase;
  selectedCompanions: string[];
};
export const ShuttleContactForm: React.FC<Props> = ({ index, passengersQ, cartShuttleItem, handleCartChange, user, firebase, selectedCompanions }) => {
  const { t } = useTranslation();
  const { companions } = useCompanions(user, firebase);

  const form = useForm({
    mode: 'all',
    defaultValues: {
      firstName: cartShuttleItem.firstName,
      lastName: cartShuttleItem.lastName,
      email: cartShuttleItem.email,
    },
    resolver: yupResolver(shuttlePassengersSchema),
  });

  const isFirstPassenger = index === 0;

  // To trigger secondary effects in the form it is necessary to use
  // on Item Update on deployment; one of this effects is to update the
  // selected companions array
  useEffect(() => {
    onItemUpdate('firstName', cartShuttleItem.firstName);
    onItemUpdate('lastName', cartShuttleItem.lastName);
    onItemUpdate('email', cartShuttleItem.email);
  }, []);

  const comparableFirstName = useWatch({ control: form.control, name: 'firstName' });
  const comparablelastName = useWatch({ control: form.control, name: 'lastName' });

  const getPassengerName = (passenger) => {
    return passenger.firstName?.toLowerCase() + passenger.lastName?.toLowerCase();
  };

  const comparableFullName = getPassengerName({ firstName: comparableFirstName, lastName: comparablelastName });

  // Only list companions that are not already in the selected companions list
  // and are NOT the same as the current companion in the form
  const availableCompanions = useMemo(() => {
    return companions?.filter((companion) => {
      const companionFullName = getPassengerName({ firstName: companion.firstName, lastName: companion.lastName });
      // If the companion is the same as the one in the form, show it
      // because it will be highlighted
      if (companionFullName === comparableFullName) {
        return true;
      }

      // If the companion is already selected, don't show it
      if (selectedCompanions.includes(companionFullName)) {
        return false;
      }

      // Otherwise, show it
      return true;
    });
  }, [companions, selectedCompanions, comparableFullName]);

  /* === HANDLERS ===================================================================== */
  const onItemUpdate = (key: string, val: string | boolean) => {
    handleCartChange(key, val);
  };

  const onSelectCompanions = (companion: ICompanion) => {
    form.reset({ firstName: companion.firstName, lastName: companion.lastName, email: companion.email });
    onItemUpdate('firstName', companion.firstName);
    onItemUpdate('lastName', companion.lastName);
    onItemUpdate('email', companion.email);
  };

  const handleFirstPassengerAutofillChange = (shouldAutoFill: boolean) => {
    if (shouldAutoFill) {
      const contactInformation = LocalStorage.getItem('CBX_Contact_Information');

      if (!contactInformation) return;

      /**
       * Fill information for the first passenger.
       */
      onSelectCompanions(contactInformation);

      return;
    }

    /**
     * Reset information for the first passenger.
     */
    onSelectCompanions({ firstName: '', lastName: '', email: '' } as ICompanion);
  };

  /* === RENDER ======================================================================= */
  return (
    <FormProvider {...form}>
      <Stack direction="column" gap={1}>
        <Typography variant="body2" color="secondary">
          {t('shuttle_shopping_cart_summary.passenger')} {index + 1} {t('of')} {passengersQ}
        </Typography>

        {isFirstPassenger && (
          <Stack direction="row" alignItems="center" gap={0.5} my={1}>
            <Controller
              control={form.control}
              name="isContactPerson"
              render={({ field }) => (
                <Checkbox
                  sx={{ color: '#6C6D70', padding: 0 }}
                  defaultChecked={cartShuttleItem.isContactPerson /** IMPORTANT: Force the UI update to display the correct state in the first render */}
                  checked={field.value}
                  onChange={(e) => {
                    field.onChange(e.target.checked);

                    onItemUpdate('isContactPerson', e.target.checked);

                    handleFirstPassengerAutofillChange(e.target.checked);
                  }}
                />
              )}
            />
            <Typography variant="body2" color="secondary.light">
              {t('checkout_contact_information.contact_person.same_as_contact_person')}
            </Typography>
          </Stack>
        )}

        {(!isFirstPassenger || (isFirstPassenger && !cartShuttleItem.isContactPerson)) && (
          <>
            {availableCompanions && availableCompanions.length > 0 && (
              <BadgeNameList control={form.control} companions={availableCompanions} setActiveCompanion={onSelectCompanions} />
            )}

            <Stack direction="column" gap={3}>
              <Grid container spacing={2.5}>
                <Grid item xs={12} md={4}>
                  <FormTextFieldController
                    name="firstName"
                    label={t('checkout_contact_information.passenger_information.inputs.name')}
                    placeholder={t('checkout_contact_information.passenger_information.inputs.name_placeholder')}
                    inputProps={{ minLength: 2, maxLength: 50 }}
                    onChange={(val) => {
                      onItemUpdate('firstName', val);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormTextFieldController
                    name="lastName"
                    label={t('checkout_contact_information.passenger_information.inputs.last_name')}
                    placeholder={t('checkout_contact_information.passenger_information.inputs.last_name_placeholder')}
                    inputProps={{ minLength: 2, maxLength: 100 }}
                    onChange={(val) => {
                      onItemUpdate('lastName', val);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormTextFieldController
                    name="email"
                    label={t('checkout_contact_information.contact_person.fields.email')}
                    placeholder={t('checkout_contact_information.contact_person.fields.email_placeholder')}
                    type="email"
                    onChange={(val) => {
                      onItemUpdate('email', val);
                    }}
                  />
                </Grid>
              </Grid>
            </Stack>
            {user && (
              <SavePassengerCheckbox
                control={form.control}
                companions={companions}
                onChange={(val) => {
                  onItemUpdate('savePassenger', val);
                }}
              />
            )}
          </>
        )}
      </Stack>
    </FormProvider>
  );
};
