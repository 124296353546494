export const FIELD_PATTERN = {
  EMAIL: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
};

export const MAX_FIELD_LENGTH = {
  FIRST_NAME: 50,
  LAST_NAME: 50,
};

export const MIN_FIELD_LENGTH = {
  PHONE: 10,
  ZIP_CODE: 3,
};

export const generateFieldPrefixByIndex = <KeyType extends string>(key: KeyType, index: number): `${KeyType}.${number}` => `${key}.${index}`;

/**
 *
 * @example
 * const fieldName = addFieldPrefix()
 * fieldName('firstName') -> 'firstName'
 *
 * const fieldName = addFieldPrefix('passengers')
 * fieldName('firstName') -> 'passengers.firstName'
 * fieldName('lastName') -> 'passengers.lastName'
 */
export const addFieldPrefix =
  <PrefixType extends string>(prefix?: PrefixType) =>
  <FieldType extends string>(field: FieldType): `${PrefixType}.${FieldType}` | FieldType =>
    prefix ? `${prefix}.${field}` : field;
