import React, { useMemo } from 'react';

import { Grid, Stack, Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm, useWatch } from 'react-hook-form';

import { parkingVehicleSchema } from '../../../../domain/checkout';
import { Parking, ParkingVehicle } from '../../../../domain/parking';
import { FormTextFieldController } from './components/text-field-controller';
import VehiclesBadgesList from '../../../Vehicles/VehiclesBadgesList';
import { useVehicles } from '../../../Vehicles/hooks/use-vehicles';
import Firebase from '../../../../legacy/components/Firebase';
import { IVehicle } from '../../../../domain/vehicles';
import SaveVehicleCheckbox from '../../../Vehicles/components/save-vehicle-checkbox';

// ********************************************************************************
type Props = {
  selectedVehicles: string[] /* vehicle license plates, already selected */;
  cartParkingItem: Parking;
  handleCartChange: (key: string, val: string | boolean) => void;
  user: any;
  firebase: Firebase;
};
export const ParkingContactForm: React.FC<Props> = ({ selectedVehicles, cartParkingItem, handleCartChange, user, firebase }) => {
  const { t } = useTranslation();
  const { vehicles } = useVehicles(user, firebase, user?.uid);

  // == State ===============================================================
  const form = useForm<ParkingVehicle>({
    mode: 'all',
    defaultValues: {
      brand: cartParkingItem.brand || '',
      model: cartParkingItem.model || '',
      year: cartParkingItem.year || '',
      color: cartParkingItem.color || '',
      vehicleLicense: cartParkingItem.vehicleLicense || '',
      state: cartParkingItem.state || '',
    },
    resolver: yupResolver(parkingVehicleSchema),
  });

  const comparableVehicleLicense = useWatch({ control: form.control, name: 'vehicleLicense' });

  // Only list vehicles that are not already in the selected vehicles list
  // and are NOT the same as the current vehicle in the form
  const availableVehicles = useMemo(() => {
    return vehicles?.filter((vehicle) => {
      // If the vehicle is the same as the one in the form, show it
      // because it will be highlighted
      if (vehicle.vehicleLicense === comparableVehicleLicense) {
        return true;
      }

      // If the vehicle is already selected, don't show it
      if (selectedVehicles.includes(vehicle.vehicleLicense)) {
        return false;
      }

      // Otherwise, show it
      return true;
    });
  }, [vehicles, selectedVehicles, comparableVehicleLicense]);

  const onItemUpdate = (key: string, val: string | boolean) => {
    handleCartChange(key, val);
  };

  const onSelectVehicle = (vehicle: IVehicle) => {
    form.reset(vehicle);
    onItemUpdate('brand', vehicle.brand);
    onItemUpdate('model', vehicle.model);
    onItemUpdate('year', vehicle.year);
    onItemUpdate('color', vehicle.color);
    onItemUpdate('vehicleLicense', vehicle.vehicleLicense);
    onItemUpdate('state', vehicle.state);
  };

  // == Render ==============================================================
  return (
    <FormProvider {...form}>
      <Stack direction="column" gap={3} mt={1}>
        <Stack direction="column" gap="8px">
          <Typography variant="body1" color="secondary" fontWeight={700}>
            {t('checkout_contact_information.parking.title')} {t('parking_summary.title')} {cartParkingItem.location}
          </Typography>
          <Typography variant="body2" color="secondary">
            {cartParkingItem.start_time.replace('T', ' @ ')} - {cartParkingItem.end_time.replace('T', ' @ ')}
          </Typography>
          <Typography variant="body2" color="secondary">
            {t('checkout_contact_information.parking.reservation_not_subject_to_vehicle')}
          </Typography>
          {availableVehicles && availableVehicles.length > 0 && (
            <VehiclesBadgesList vehicles={availableVehicles} setActiveVehicle={onSelectVehicle} comparableVehicleLicense={comparableVehicleLicense} />
          )}
        </Stack>

        <Stack direction="column" gap={3}>
          <Grid container spacing={2.5}>
            <Grid item xs={12} md={4}>
              <FormTextFieldController
                name="brand"
                onChange={(val) => {
                  onItemUpdate('brand', val);
                }}
                label={t('checkout_contact_information.parking.inputs.car_brand')}
                placeholder={t('checkout_contact_information.parking.inputs.car_brand_placeholder')}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormTextFieldController
                name="model"
                onChange={(val) => {
                  onItemUpdate('model', val);
                }}
                label={t('checkout_contact_information.parking.inputs.car_model')}
                placeholder={t('checkout_contact_information.parking.inputs.car_model_placeholder')}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack direction="row" gap={3}>
                <FormTextFieldController
                  name="year"
                  type="number"
                  onChange={(val) => {
                    onItemUpdate('year', val);
                  }}
                  label={t('checkout_contact_information.parking.inputs.car_year')}
                  placeholder={t('checkout_contact_information.parking.inputs.car_year_placeholder')}
                />
                <FormTextFieldController
                  name="color"
                  onChange={(val) => {
                    onItemUpdate('color', val);
                  }}
                  label={t('checkout_contact_information.parking.inputs.car_color')}
                  placeholder={t('checkout_contact_information.parking.inputs.car_color_placeholder')}
                />
              </Stack>
            </Grid>
          </Grid>

          <Grid container spacing={2.5}>
            <Grid item xs={12} md={4}>
              <FormTextFieldController
                name="vehicleLicense"
                onChange={(val) => {
                  onItemUpdate('vehicleLicense', val);
                }}
                label={t('checkout_contact_information.parking.inputs.car_license_plate')}
                placeholder={t('checkout_contact_information.parking.inputs.car_license_placeholder')}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormTextFieldController
                name="state"
                onChange={(val) => {
                  onItemUpdate('state', val);
                }}
                label={t('checkout_contact_information.parking.inputs.car_state')}
                placeholder={t('checkout_contact_information.parking.inputs.car_state_placeholder')}
              />
            </Grid>
          </Grid>
          {user && (
            <SaveVehicleCheckbox
              control={form.control}
              vehicles={vehicles}
              onChange={(val) => {
                onItemUpdate('saveVehicle', val);
              }}
            />
          )}
        </Stack>
      </Stack>
    </FormProvider>
  );
};
