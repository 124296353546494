import React, { ReactNode } from 'react';

import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent, SelectProps, Stack, Typography } from '@mui/material';

// ********************************************************************************
// Controlled select component for forms, can be used with react-hook-form or
// standalone. Only essential props are exposed, but it accepts all SelectProps
// for more advanced usage.
export type FormSelectProps = {
  name?: string;
  value: string;
  onChange: (event: SelectChangeEvent<unknown>, child: ReactNode) => void;
  options: { label: string; value: any }[];
  label: string;
  // Dictates the presence of the label, if false, the label will be rendered
  // above the select component, if true, the label will be rendered inside the
  // select component.
  withTitle?: boolean;
  gap?: boolean;
  error?: boolean;
  helperText?: string;
} & SelectProps;
export const FormSelect = ({ name, value, onChange, options, label, withTitle, error, helperText, gap, ...props }: FormSelectProps) => {
  // == Render ====================================================================
  return (
    <Stack direction="column" gap="8px">
      {withTitle && (
        <Typography variant="body2" color="secondary" fontWeight={700}>
          {label}
        </Typography>
      )}
      <FormControl fullWidth error={error}>
        {!withTitle && <InputLabel id={name}>{label}</InputLabel>}
        <Select name={name} value={value} onChange={onChange} label={!withTitle ? label : undefined} {...props}>
          {options.map((location) => (
            <MenuItem sx={{ textWrap: 'pretty' }} key={location.value} value={location.value}>
              {location.label}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    </Stack>
  );
};
