import { v4 as uuidV4 } from 'uuid';


// ********************************************************************************
// This form is used exclusively in the companions process to collect and validate
// the contact information of the user's companions.
// NOTE: This form only purpose is to render and validate.

export const useVehicles = (currentUser, firebase, uid) => {
  const vehicles = currentUser?.vehicles_v2 ?? [];

  /**
   * @param {IVehicle[]} updateVehicles
   * @param {boolean} updateFirebase
   */
  const update = async (updateVehicles, updateFirebase = true) => {
    window.localStorage.setItem('CBX_USER_DATA', {...currentUser, vehicles_v2: updateVehicles});
    setVehicleId(updateVehicles);
    /*In case we don't want to use firebase just set the flag to false*/
    if(!updateFirebase) return;
    return await firebase.update(uid, { vehicles_v2: updateVehicles});
  }
  
  /**
   * @param {IVehicle} vehicle
   *
   * @returns {IVehicle}
   */
  const setVehicleId = vehicle => ({ ...vehicle, id: uuidV4() });

  /**
   * @param {IVehicle | IVehicle[]} newVehicles
   */
  const addVehicles = async newVehicles => {
    if (Array.isArray(newVehicles)) {
      await addMultipleVehicles(newVehicles);
      return;
    }

    await addOneVehicle(newVehicles);
  };

  
  /**
   * @param {IVehicle} vehicle
   */
  const addOneVehicle = async vehicle => {
    /**
     * Update user data.
     */
    await update([...vehicles, setVehicleId(vehicle)]);
  };

  /**
   *
   * @param {IVehicle[]} newVehicles
   */
  const addMultipleVehicles = async newVehicles => {
    /**
     * Create an array to store the vehicles that will be added.
     */
    const validVehicles = newVehicles.map(setVehicleId);

    /**
     * Update user data.
     */
    await update([...vehicles, ...validVehicles]);
  };

  /**
   * @param {IVehicle} vehicle
   */
  const updateVehicle = async vehicle => {
    const indexOfSelectedVehicle = getIndexOfExistingVehicle(vehicle);
    /**
     * In case the selected vehicle does not exist in the array,
     * it will not can updated.
     */
    if (indexOfSelectedVehicle === -1) return;

    const vehiclesCopy = [...vehicles];
    vehiclesCopy[indexOfSelectedVehicle] = { ...vehicle }; 

    /**
     * Update user data.
    */
    await update(vehiclesCopy);
  };

  /**
   * @param {IVehicle} vehicle
   */
  const deleteVehicle = async vehicle => {
    /**
     * Remove the selected vehicle from the list of vehicles, filtering by `id`.
     */
    const filteredVehicles = vehicles.filter(savedVehicle => savedVehicle.id !== vehicle.id);

    /**
     * Update user data.
     */
    await update(filteredVehicles);
  };

  /**
   * @param {IVehicle} vehicle
   */
  const getIndexOfExistingVehicle = vehicle => {
    return vehicles.findIndex(savedVehicle => savedVehicle.id === vehicle.id);
  };

  /* === RETURN ======================================================================= */
  return {
    vehicles,
    addVehicles,
    deleteVehicle,
    updateVehicle,
  };
};


