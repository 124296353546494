import React from "react";
import { SvgIcon } from "@mui/material";

export default function DeleteIcon() {
  return (
    <SvgIcon>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_6340_126753"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="16"
          height="16"
        >
          <rect width="16" height="16" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_6340_126753)">
          <path
            d="M4.66602 14C4.29935 14 3.98546 13.8694 3.72435 13.6083C3.46324 13.3472 3.33268 13.0333 3.33268 12.6667V4H2.66602V2.66667H5.99935V2H9.99935V2.66667H13.3327V4H12.666V12.6667C12.666 13.0333 12.5355 13.3472 12.2743 13.6083C12.0132 13.8694 11.6993 14 11.3327 14H4.66602ZM5.99935 11.3333H7.33268V5.33333H5.99935V11.3333ZM8.66602 11.3333H9.99935V5.33333H8.66602V11.3333Z"
            fill="#6C6D70"
          />
        </g>
      </svg>
    </SvgIcon>
  );
}
