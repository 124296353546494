import React from 'react';

import { Stack, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import DeleteIcon from '../../../components/Icons/DeleteIcon';
import { isRoundTicketInsurance, TicketInsurance } from '../domain';
import { formatDateToString } from '../utils/insurance-utils';
import { DotDelimiter } from './insurance-order-summary';

// ********************************************************************************
interface Props {
  insurance: TicketInsurance;
  onEdit: () => void;
  onRemove: () => void;
}
export const InsuranceShoppingCartSummary = ({ insurance, onEdit, onRemove }: Props) => {
  const { t } = useTranslation();
  // const planT = (key: string) => t(`travel_club_service.plans.${travelClub.plan.key}.${key}`);

  return (
    <Stack gap="16px" padding="16px 0px" direction="column" borderBottom="1px solid rgba(0, 0, 0, 0.12)">
      <Stack direction="column">
        <Stack width="100%" direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="body1" color="secondary.light" fontWeight={700}>
            {t('insurance_service.travel_insurance')}
          </Typography>
          <Stack direction="row">
            <IconButton onClick={onRemove}>
              <DeleteIcon />
            </IconButton>
          </Stack>
        </Stack>
        <Stack width="100%" direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="body1" color="secondary.light">
            {insurance.destination === 'Mexico' ? t('USA') : t('Mexico')} - {t(insurance.destination)}
          </Typography>
        </Stack>
        <Stack width="100%">
          <Stack gap="8px" direction="row" alignItems="center">
            <Typography color="secondary.light" variant="caption">
              {formatDateToString(insurance.startDate)}
            </Typography>
            <DotDelimiter />

            {/* {planT('title')} */}
            {isRoundTicketInsurance(insurance) ? (
              <>
                <Typography color="secondary.light" variant="caption">
                  {formatDateToString(insurance.endDate)}
                </Typography>
                <DotDelimiter />
                <Typography color="secondary.light" variant="caption">
                  {t('insurance_service.card.travel_type.round_trip')}
                </Typography>
              </>
            ) : (
              <>
                <Typography color="secondary.light" variant="caption">
                  {t('insurance_service.card.travel_type.one_way')}
                </Typography>
                <DotDelimiter />
                <Typography color="secondary.light" variant="caption">
                  {insurance.coverageDays} {t('insurance_service.card.days')}
                </Typography>
              </>
            )}
          </Stack>
          <Stack flexDirection="row" justifyContent="flex-end" mt={1}>
            <Typography variant="body2" color="secondary.light">
              ${insurance.total.toFixed(2)} USD
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
