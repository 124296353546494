import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { isRoundTicketInsurance, TicketInsurance } from '../domain';
import { formatDateToString } from '../utils/insurance-utils';

interface Props {
  insurance: TicketInsurance;
  onRemove: () => void;
}

export const DotDelimiter = () => (
  <Box
    sx={{
      width: 4,
      height: 4,
      borderRadius: '100%',
      bgcolor: '#EF60A3',
    }}
  />
);

export const InsuranceOrderSummary = ({ insurance, onRemove }: Props) => {
  // TODO: Integrar traducciones para el componente
  const { t } = useTranslation();

  return (
    <Stack width="fit-content" gap="16px" direction="column">
      <Stack direction="column">
        <Stack direction="row" gap={1} alignItems={'center'} justifyContent="space-between">
          <Typography color="#333334" fontWeight={700}>
            {t('insurance_service.travel_insurance')}
          </Typography>
          <Stack direction="row" gap={0.5}>
            <IconButton onClick={onRemove}>
              <DeleteIcon fontSize="small" />
            </IconButton>
            {/* <IconButton onClick={onRemove}>
              <EditIcon fontSize="small" />
            </IconButton> */}
          </Stack>
        </Stack>

        {/* ================= Header ================= */}
        <Stack width="100%" direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="body1" color="secondary.light" fontWeight={700}>
            {insurance.destination === 'Mexico' ? t('USA') : t('Mexico')} - {t(insurance.destination)}
          </Typography>
        </Stack>

        {/* ================= Body ================= */}
        <Stack gap="8px" direction="row" alignItems="center">
          <Typography color="secondary.light" variant="caption" fontStyle="italic" fontWeight={500}>
            {formatDateToString(insurance.startDate)}
          </Typography>
          <DotDelimiter />

          {isRoundTicketInsurance(insurance) ? (
            <>
              <Typography color="secondary.light" variant="caption" fontStyle="italic" fontWeight={500}>
                {formatDateToString(insurance.endDate)}
              </Typography>
              <DotDelimiter />
              <Typography color="secondary.light" variant="caption" fontStyle="italic" fontWeight={500}>
                {t('insurance_service.card.travel_type.round_trip')}
              </Typography>
            </>
          ) : (
            <>
              <Typography color="secondary.light" variant="caption" fontStyle="italic" fontWeight={500}>
                {t('insurance_service.card.travel_type.one_way')}
              </Typography>
              <DotDelimiter />
              <Typography color="secondary.light" variant="caption" fontStyle="italic" fontWeight={500}>
                {insurance.coverageDays} {t('insurance_service.card.days')}
              </Typography>
            </>
          )}
        </Stack>
      </Stack>

      {/* ================= Footer ================= */}
      <Stack width="100%" minWidth={260} direction="row" justifyContent="space-between" alignItems="baseline">
        <Stack direction="column">
          <Typography variant="body1" fontWeight={500}>
            x1
          </Typography>
        </Stack>
        <Typography variant="body2" color="secondary">
          ${insurance.total?.toFixed(2)} USD
        </Typography>
      </Stack>
      <Stack display="flex" flexDirection="row" justifyContent="flex-start">
        {/* TODO: Validar el provider correspondiente */}
        {/*{insurance.provider === InsuranceProvider.BATTLEFACE &&*/}
        {/* <img src="/images/Insurance/battleface-logo.svg" alt="Battleface logo" style={{ width: '5rem' }} /> */}
        {/*}*/}
        {/*{insurance.provider === InsuranceProvider.PANAMERICAN &&*/}
        {/*  <img*/}
        {/*    src="/images/Insurance/panamerican-logo.svg"*/}
        {/*    alt="Panamerican logo"*/}
        {/*    style={{ width: '5rem' }}*/}
        {/*  />*/}
        {/*}*/}
      </Stack>
    </Stack>
  );
};
