import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { Stack, Typography } from '@mui/material';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';

interface Props extends Omit<DatePickerProps<Dayjs>, 'name' | 'onChange'> {
  name?: string;
  placeholder: string;
  error?: string;
  disableMinDate?: boolean;
  onChange: (date: string) => void;
}

export const FormDatePicker = ({ name, label, placeholder, maxDate, minDate, disableMinDate = false, onChange, ...props }: Props) => {
  /* === STATES ======================================================================= */
  const minimumDate = minDate ?? dayjs();

  /* === HANDLERS ===================================================================== */
  const handleDateChange = (date: Dayjs | null) => {
    if (!date) return;
    if (!date.isValid()) return;

    onChange(date.format('YYYY-MM-DD'));
  };

  /* === RENDER ======================================================================= */
  return (
    <Stack direction="column" gap="8px">
      <Typography variant="body2" color="secondary" fontWeight={700}>
        {label}
      </Typography>

      <DatePicker
        {...props}
        value={props.value ? dayjs(props.value, 'YYYY-MM-DD') : null}
        label={placeholder}
        maxDate={maxDate}
        minDate={disableMinDate ? undefined : minimumDate}
        sx={{ width: '100%' }}
        onChange={handleDateChange}
        slotProps={{
          textField: {
            fullWidth: true,
            variant: 'outlined',
            error: !!props.error,
            helperText: props.error ?? '',
          },
        }}
      />
    </Stack>
  );
};
