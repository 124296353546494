import { createContext } from 'react';

import { SingleTicketInsurance, TicketInsurance } from '../domain';

// ********************************************************************************
export interface InsuranceContextState {
  insurances: TicketInsurance[];
  getTicketInsurances: () => void;
  onTicketInsuranceChange: (ticketInsurance: TicketInsurance) => void;
  onCoverageDaysChange: (ticketInsurance: SingleTicketInsurance) => Promise<void>;
  isLoading: boolean;
  isError: boolean;
  isDone: boolean;
}

export const initialInsuranceContext: InsuranceContextState = {
  insurances: [],
  getTicketInsurances: () => {},
  onTicketInsuranceChange: () => {},
  onCoverageDaysChange: async () => {},
  isLoading: false,
  isError: false,
  isDone: false,
};

export const InsuranceContext = createContext(initialInsuranceContext);
