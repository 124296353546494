import { isInsuranceFeatureEnabled } from '../../features/insurance/domain';
import { LocalStorage } from '../../utils';
import { Yup } from '../../utils/yup-locale';
import { IPassenger, Nationality } from '../common';
import { IContactPerson } from '../contact-information';
import { MEXICO_COUNTRY_ID, USA_COUNTRY_ID } from './countries';


const lettersRegex = /^[A-Za-zÁÉÍÓÚáéíóúñÑ ]+$/;
// ********************************************************************************
// Form for the checkout process, it is used to store the contact information of
// the user, along with information of the additional services that the user may
// have selected.
// NOTE: This is for the Contact Information tab in the checkout process only. This
//       form only purpose is to render and validate, payload construction is
//       delegated to the ShoppingCartContext.
// SEE: src/context/ShoppingCart/use-shopping-cart-context.tsx
export interface CheckoutContactInformation extends IContactPerson {
  travelers: ServiceTravelers[];
}

export const CONTACT_INFORMATION_LOCAL_STORAGE_KEY = 'CBX_Contact_Information';
export const getContactInformation = (): CheckoutContactInformation | null => {
  return LocalStorage.getItem(CONTACT_INFORMATION_LOCAL_STORAGE_KEY);
};

export type ServiceTravelers = {
  ticketId: string;
  values: TravelerInformation[];
};

export interface TravelerInformation extends IPassenger {
  nationality: Nationality;
  countryOfResidence: string;
  stateOfResidence: string;
}

export const DEFAULT_CHECKOUT_CONTACT_INFORMATION: CheckoutContactInformation = {
  firstName: '',
  lastName: '',
  nationality: '' as Nationality,
  email: '',
  emailConfirm: '',
  zipCode: '',
  phone: '',

  travelers: [],
  countryOfResidence: '', 
  stateOfResidence: '',
};

export const DEFAULT_TRAVELER_INFORMATION: TravelerInformation = {
  firstName: '',
  lastName: '',
  nationality: '' as Nationality,
  countryOfResidence: '',
  stateOfResidence: '',
};

export const DEFAULT_MULTIPLE_INMIGRATION_FORM_VALUES = {
  firstName: '',
  lastName: '',
  birthdate: '',
  gender: '',
  countryOfBirth: '',
  passportNumber: '',
  passportExpirationDate: '',
  countryOfExpedition: '',
  countryOfResidence: '',
};

// -- Schema --------------------------------------------------------------------
export const checkoutContactInformationSchema = Yup.object().shape({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  nationality: Yup.string().required(),
  email: Yup.string().email().required(),
  emailConfirm: Yup.string()
    .email()
    .oneOf([Yup.ref('email')], 'yup.string.emailMismatch')
    .required(),
  zipCode: Yup.string().required(),
  phone: Yup.string().required(),
  countryOfResidence: isInsuranceFeatureEnabled() ? Yup.string().required() : Yup.string().optional(),
  stateOfResidence: Yup.string().when('countryOfResidence', {
    is: (value: string) => value === `${USA_COUNTRY_ID}` || value === `${MEXICO_COUNTRY_ID}`,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.optional(),
  }),
  travelers: Yup.array().of(
    Yup.object()
      .shape({
        ticketId: Yup.string(),
        values: Yup.array().of(
          Yup.object().shape({
            firstName: Yup.string().required(),
            lastName: Yup.string().required(),
            nationality: Yup.string().required(),
            countryOfResidence: isInsuranceFeatureEnabled() ? Yup.string().required() : Yup.string().optional(),
            stateOfResidence: Yup.string().when('countryOfResidence', {
              is: (value: string) => value === `${USA_COUNTRY_ID}` || value === `${MEXICO_COUNTRY_ID}`,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.optional(),
            }),
          }),
        ),
      })
      .nullable(),
  ),
});
export const passengerInformationSchema = Yup.object().shape({
  age: Yup.number().required(),
  countryOfResidence: Yup.number().required(),
  firstName: Yup.string().required(),
  gender: Yup.string().required(),
  lastName: Yup.string().required(),
  nationality: Yup.string().required(),
  telephone: Yup.string().required(),
}).optional().default(undefined);

export const assistedDataSchema = Yup.array().of(
  Yup.object({
    passengerInformation: passengerInformationSchema
  })
);



export const shuttlePassengersSchema = Yup.object().shape({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  email: Yup.string().email().required(),
});

export const parkingVehicleSchema = Yup.object().shape({
  brand: Yup.string().required().trim().max(100),
  model: Yup.string().required().trim().max(100),
  year: Yup.string()
    .required()
    .trim()
    .matches(/^\d{1,4}$/, 'yup.number.integer')
    .test('valid-year-range', 'yup.string.vehicleYear', (value) => {
      if (!value) return false;
      const year = parseInt(value, 10);
      const nextYear = new Date().getFullYear() + 1; // Año actual +1
      return year >= 1900 && year <= nextYear;
    }),
  color: Yup.string()
    .required()
    .trim()
    .max(100)
    .matches(lettersRegex, 'yup.string.alphabetical'),
  vehicleLicense: Yup.string()
    .required()
    .trim()
    .max(100)
    .matches(/^(?![^A-Za-z0-9]+$)[A-Za-z0-9 -]+$/, 'yup.string.specialCharacters'),
  state: Yup.string()
    .required()
    .trim()
    .max(100)
    .matches(lettersRegex, 'yup.string.alphabetical'),
});

export enum RecaptchaAction {
  initPayment = 'initPayment',
  processPayment = 'processPayment',
}

export const isRecaptchaFeatureActive = () => {
  return process.env.GATSBY_FEATURE_RECAPTCHA_ENABLED !== undefined;
};
