import { IPassenger, Nationality } from '../common';
import { TicketType } from './ticket-type';

export interface PassengersByTicketType {
  /**
   *  Adults are considered for one-way or round-trip trips
   *  - TicketType.AdultOneWay | TicketType.AdultRoundTrip
   */
  adult: number;
  [TicketType.AsistedPass]: number;
  [TicketType.UnaccompaniedMinor]: number;
}

export const getPassengersQuantity = () => Array.from({ length: 16 }, (_, index) => ({ name: index, value: index }));

export enum TicketPassengerField {
  FirstName = 'firstName',
  LastName = 'lastName',
  Nationality = 'nationality',
  CountryOfResidence = 'countryOfResidence',
  StateOfResidence = 'stateOfResidence',
  SavePassenger = 'savePassenger',
}

export interface ITicketPassenger extends IPassenger {
  nationality: Nationality;
  countryOfResidence: string;
  stateOfResidence: string;
}
