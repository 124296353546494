import { Box, Typography } from '@mui/material';
import React from 'react';
import { PINK } from '../../legacy/constants/colors';

export default function VehicleBadge({ vehicle, onlyBadge = false, setActiveVehicle, comparableVehicleLicense }) {
  const isSelected = comparableVehicleLicense === vehicle.vehicleLicense;
  return (
    <Box
      className="rounded"
      onClick={() => {
        setActiveVehicle && setActiveVehicle(vehicle);
      }}
      sx={{
        backgroundColor: '#3F2A55',
        color: 'white',
        borderRadius: '8px !important',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        border: 'transparent 3px solid',
        alignItems: 'center',
        flexShrink: 1,
        minWidth: 94,
        maxWidth: 120,
        height: 48,
        ...(onlyBadge && {
          cursor: 'pointer',
          '&:hover': {
            border: `3px solid ${PINK}`,
            borderRadius: '24px',
          },
        }),
        ...(isSelected && {
          border: `3px solid ${PINK}`,
        }),
      }}
    >
      <Typography sx={{ display: '-webkit-box', WebkitLineClamp: 1, WebkitBoxOrient: 'vertical', overflow: 'hidden', textOverflow: 'ellipsis' }} fontWeight="bold" color="white">
        {vehicle.vehicleLicense}
      </Typography>
      {onlyBadge && (
        <Typography
          sx={{ display: '-webkit-box', WebkitLineClamp: 1, WebkitBoxOrient: 'vertical', overflow: 'hidden', textOverflow: 'ellipsis' }}
          fontWeight="bold"
          color="white"
          fontSize="14px"
        >
          {vehicle.brand} {vehicle.model}
        </Typography>
      )}
    </Box>
  );
}
