export enum Nationality {
  American = 'american',
  Mexican = 'mexican',
  Other = 'other',
}

export const isAmerican = (nationality: Nationality) =>
  nationality === Nationality.American || /** This validation is to maintain backward compatibility */ (nationality as string) === 'usa';

export const isMexican = (nationality: Nationality) =>
  nationality === Nationality.Mexican || /** This validation is to maintain backward compatibility */ (nationality as string) === 'mex';
