export default async function getInamiService({ locale }) {
  let localeMap = locale;
  if (!localeMap) localeMap = "en";

  const data = await fetch(`${process.env.GATSBY_BASE_API}/getCountriesCatalog?language=${localeMap}`);
  const json = await data.json();

  console.log('countries');
  console.log(json.data);

  return json.data;
}
