import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Stack, Typography } from '@mui/material';
import { Nationality } from '../../../../domain/contact-information';
import {
  AssistedTicketFlight,
  AssistedTicketPassenger,
  TicketUnaccompaniedMinorDocumentation,
  TicketUnaccompaniedMinorTutor,
  canCrossToNorth,
  canCrossToSouth,
  canNotAmericanCrossToNorth,
  canOtherCrossToNorth,
  getUnaccomopaniedMinorAirlines,
} from '../../../../domain/tickets';
import { isOneWayTrip } from '../../../../domain/travels';
import { Yup } from '../../../../utils/yup-locale';
import { AssistedTicketFlightForm } from './assisted-ticket-flight-form';
import { AssistedTicketPassengerForm } from './assisted-ticket-passenger-form';
import { DropzoneUploader } from './drop-zone-uploader';
import { UnaccompaniedMinorTutorForm } from './unaccompanied-minor-tutor-form';
import { TicketsService } from '../../../../services/tickets';
//import { useCompanions } from '../../../travelers/hooks/use-companion';
import Firebase from '../../../../legacy/components/Firebase';
import { MEXICO_COUNTRY_ID, USA_COUNTRY_ID } from '../../../../domain/checkout';
import { isInsuranceFeatureEnabled } from '../../../insurance/domain';

const assistedTicketFlightSchema = Yup.object().shape({
  airline: Yup.string(),
  date: Yup.string(),
  destination: Yup.string(),
  flightNumber: Yup.string(),
  origin: Yup.string(),
});

export const assistedTicketPassengerSchema = Yup.object().shape({
  age: Yup.number()
    .min(13, 'Min 13')
    .max(17, 'Max 17')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required(),
  firstName: Yup.string().required(),
  gender: Yup.string().required(),
  lastName: Yup.string().required(),
  nationality: Yup.string().required(),
  telephone: Yup.string().required(),
  countryOfResidence: isInsuranceFeatureEnabled() ? Yup.string().required() : Yup.string().optional(),
  stateOfResidence: Yup.string().when('countryOfResidence', {
    is: (value: string) => value === `${USA_COUNTRY_ID}` || value === `${MEXICO_COUNTRY_ID}`,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.optional(),
  }),
  //savePassenger: Yup.boolean(),
});

interface Props {
  ticket: any;
  onFlightChange: (field: keyof AssistedTicketFlight, value: string) => void;
  onPassengerChange: (field: keyof AssistedTicketPassenger, value: number | string) => void;
  onTutorChange: (field: keyof TicketUnaccompaniedMinorTutor, value: string) => void;
  user: any;
  firebase: Firebase;
}

export const UnaccompaniedMinorContactInfoForm = ({ ticket, onFlightChange, onPassengerChange, onTutorChange, user, firebase }: Props) => {
  const { t } = useTranslation();
  const travelTypei18n = isOneWayTrip(ticket.tripType) ? 'trip_types.one_way' : 'trip_types.round_trip';
  //const {companions}= useCompanions(user, firebase);

  const [nationality, setNationality] = useState<Nationality | ''>(ticket.assisted_data.passengerInformation.nationality);

  const handlePassengerChange = (field: keyof AssistedTicketPassenger, value: number | string) => {
    if (field === 'nationality') {
      setNationality(value as Nationality);
    }

    onPassengerChange(field, value);
  };

  const handleUploadTempFile = async (documentName: TicketUnaccompaniedMinorDocumentation, fileBuffer: any, fileType: string) => {
    const service = new TicketsService();
    try {
      await service.uploadUnaccompaniedMinorTempFile({
        fileBuffer,
        fileName: documentName,
        tempFileId: ticket.assisted_data.tempFileId,
        fileBufferType: fileType,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Stack gap={1}>
        <Typography fontWeight={700}>
          {t('ticket')} {t(travelTypei18n)}
        </Typography>
        <Stack flexDirection="row" gap={1}>
          <Typography fontWeight={700}>{t('tickets_service.passenger_types.minor_unaccompanied')}:</Typography>
          <Stack flexDirection="row" gap={2}>
            <Typography>{ticket.origin}</Typography>
            <Typography>-</Typography>
            <Typography>{ticket.destination}</Typography>
          </Stack>
        </Stack>
      </Stack>

      <AssistedTicketPassengerForm
        passenger={ticket.assisted_data.passengerInformation}
        validationSchema={assistedTicketPassengerSchema}
        onChange={handlePassengerChange}
        //companions={companions}
      />
      <AssistedTicketFlightForm
        airlines={getUnaccomopaniedMinorAirlines()}
        flight={ticket.assisted_data.flightInformation}
        validationSchema={assistedTicketFlightSchema}
        onChange={onFlightChange}
      />
      <UnaccompaniedMinorTutorForm tutor={ticket.assisted_data.tutorInformation} onChange={onTutorChange} />

      <Stack direction="column" gap={3} mt={1}>
        <Typography variant="body1" fontWeight={700}>
          {t('checkout_contact_information.documents.title')}
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Stack direction="column" gap="8px">
              <Typography variant="body1" fontWeight={700}>
                {t('checkout_contact_information.documents.form_unmr')}
              </Typography>
              <DropzoneUploader onChange={(buffer, fileType) => handleUploadTempFile(TicketUnaccompaniedMinorDocumentation.UNMRForm, buffer, fileType)} />
            </Stack>
          </Grid>

          {canNotAmericanCrossToNorth(ticket.destination, nationality) && (
            <Grid item xs={12} md={6}>
              <Stack direction="column" gap="8px">
                <Typography variant="body1" fontWeight={700}>
                  {t('checkout_contact_information.documents.i94_permit')}
                </Typography>
                <DropzoneUploader onChange={(buffer, fileType) => handleUploadTempFile(TicketUnaccompaniedMinorDocumentation.I94Permit, buffer, fileType)} />
              </Stack>
            </Grid>
          )}
        </Grid>

        <Grid container spacing={3}>
          {canNotAmericanCrossToNorth(ticket.destination, nationality) && canOtherCrossToNorth(ticket.destination, nationality) && (
            <Grid item xs={12} md={6}>
              <Stack direction="column" gap="8px">
                <Typography variant="body1" fontWeight={700}>
                  {t('checkout_contact_information.documents.sam')}
                </Typography>
                <DropzoneUploader onChange={(buffer, fileType) => handleUploadTempFile(TicketUnaccompaniedMinorDocumentation.SAMForm, buffer, fileType)} />
              </Stack>
            </Grid>
          )}

          {canCrossToNorth(ticket.destination, nationality) && (
            <Grid item xs={12} md={6}>
              <Stack direction="column" gap="8px">
                <Typography variant="body1" fontWeight={700}>
                  {t('checkout_contact_information.documents.notarized_letter')}
                </Typography>
                <DropzoneUploader onChange={(buffer, fileType) => handleUploadTempFile(TicketUnaccompaniedMinorDocumentation.NotarizedLetter, buffer, fileType)} />
              </Stack>
            </Grid>
          )}
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Stack direction="column" gap="8px">
              <Typography variant="body1" fontWeight={700}>
                {t('checkout_contact_information.documents.passport')}
              </Typography>
              <DropzoneUploader onChange={(buffer, fileType) => handleUploadTempFile(TicketUnaccompaniedMinorDocumentation.Passport, buffer, fileType)} />
            </Stack>
          </Grid>
          {canNotAmericanCrossToNorth(ticket.destination, nationality) && (
            <Grid item xs={12} md={6}>
              <Stack direction="column" gap="8px">
                <Typography variant="body1" fontWeight={700}>
                  {t('checkout_contact_information.documents.visa')}
                </Typography>
                <DropzoneUploader onChange={(buffer, fileType) => handleUploadTempFile(TicketUnaccompaniedMinorDocumentation.Visa, buffer, fileType)} />
              </Stack>
            </Grid>
          )}
        </Grid>

        <Grid container spacing={3}>
          {canCrossToSouth(ticket.destination, nationality) && (
            <Grid item xs={12} md={6}>
              <Stack direction="column" gap="8px">
                <Typography variant="body1" fontWeight={700}>
                  {t('checkout_contact_information.documents.fmm_form')}
                </Typography>
                <DropzoneUploader onChange={(buffer, fileType) => handleUploadTempFile(TicketUnaccompaniedMinorDocumentation.FMMForm, buffer, fileType)} />
              </Stack>
            </Grid>
          )}
          {ticket.destination === 'Mexico' && (
            <Grid item xs={12} md={6}>
              <Stack direction="column" gap="8px">
                <Typography variant="body1" fontWeight={700}>
                  {t('checkout_contact_information.documents.tutor_id')}
                </Typography>
                <DropzoneUploader onChange={(buffer, fileType) => handleUploadTempFile(TicketUnaccompaniedMinorDocumentation.TutorID, buffer, fileType)} />
              </Stack>
            </Grid>
          )}
        </Grid>
      </Stack>
    </>
  );
};
