import React from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { Checkbox, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IVehicle } from '../../../domain/vehicles';

type Props = {
  control: any;
  vehicles: IVehicle[];
  onChange: (val: boolean) => void;
};

const SaveVehicleCheckbox = ({ control, vehicles, onChange }: Props) => {
  const { t } = useTranslation();

  const vehicleLicense = useWatch({ control, name: 'vehicleLicense' });

  /**
   *
   * @param {IVehicle[]} vehicle
   */
  const isVehicleSelected = (vehicle) =>
    vehicle.vehicleLicense.toLowerCase() ===
  vehicleLicense?.toLowerCase();

  /* === RENDER ================================================================ */
  if (vehicles.find(isVehicleSelected)) return null;

  return (
    <Stack direction="row" alignItems="center" gap={0.5}>
      <Controller
        control={control}
        name="saveVehicle"
        render={({ field }) => (
          <Checkbox
            sx={{ color: '#6C6D70', padding: 0 }}
            checked={field.value}
            value={field.value}
            onChange={(e) => {
              field.onChange(e.target.checked);
   
              onChange(e.target.checked);
            }}
          />
        )}
      />
      <Typography variant="body2" color="secondary.light">
        {t('checkout_contact_information.vehicles.save_vehicle')}
      </Typography>
    </Stack>
  );
};

export default SaveVehicleCheckbox;
