import { useTranslation } from 'gatsby-plugin-react-i18next';

import { Nationality } from '../domain/common';

export const useNationalities = () => {
  /* === STATES ======================================================================= */
  const { t } = useTranslation();

  const nationalities = [
    { value: Nationality.Mexican, label: t('nationalities.mexican') },
    { value: Nationality.American, label: t('nationalities.american') },
    { value: Nationality.Other, label: t('nationalities.other') },
  ];

  /* === RETURN ======================================================================= */
  return { nationalities };
};
