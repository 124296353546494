export class LocalStorage {
  public static getItem<T = any>(key: string) {
    const item = window.localStorage.getItem(key);
    return /** It's truthy value */ item && item !== /** This string cannot be parsed */ 'undefined' ? (JSON.parse(item) as T) : null;
  }

  public static setItem(key: string, data: any) {
    const sanitizedData = typeof data === 'object' ? JSON.stringify(data) : data;
    window.localStorage.setItem(key, sanitizedData);
  }

  public static removeItem(key: string) {
    window.localStorage.removeItem(key);
  }
}
